import Ajax from './ajax';
const Request = {
  public: {
    config(params) {
      return Ajax.post('/index/config', params);
    },
    uploadimgs(params) {
      return Ajax.post('/Api/Common/upload_img', params);
    },
    deal_lang_pack(params) { //获取语言包翻译
      return Ajax.post('/Api/Translate/get_lang_pack', params);
    },
    get_rate_list(params) { //获取语言包翻译
      return Ajax.post('/Api/Common/get_rate_list', params);
    },
    

  },
  //用户注册登陆
  signIn: {
    // 登录
    login(params) {
      return Ajax.post('/index/user/login', params);
    },
    // 注册
    register(params) {
      return Ajax.post('/index/user/register', params);
    },
  },
  //首页
  home: {
    banner(params) {
      return Ajax.post('/index/lists', params);
    },
    // 返利攻略
    fanliStrategy(params){
      return Ajax.post('/index/category', params);
    },
    // 攻略详情
    StrategyDetail(params){
      return Ajax.post('/index/article', params);
    },
    // 最新发布
    latestRelease(params){
      return Ajax.post('/index/newest', params);
    },
    // 根据推广链接生成 淘口令
    create_tao_pass(params){
      return Ajax.post('/index/goods/create_tkl', params);
    },
    // 获取商品详情
    get_goods_info(params){
      return Ajax.post('/index/goods/detail', params);
    },
    // 获取1688商品详情
    get_aligoods_info(params){
      return Ajax.post('/index/goods/alidetail', params);
    },
    // 获取拼多多商品详情
    get_pddgoods_info(params){
      return Ajax.post('/index/goods/pdddetail', params);
    },
    // 获取拼多多推荐商品
    get_pddgoods_tuijian(params){
      return Ajax.post('/index/goods/pddlists', params);
    },
    // 获取拼多多推荐商品返利连接
    get_pddgoods_fanli(params){
      return Ajax.post('/index/goods/pddgeturl', params);
    },
    // 获取首页商品
    get_goods(params){
      return Ajax.post('/index/goods/lists', params);
    },
    // 语言包获取
    get_lang(params){
      
      return Ajax.post('/index/lang', params);
    }
  },
  //商品数据
  product: {
    cate(params) {
      return Ajax.post('/Api/Index/get_cate', params);
    },
  },
  // 订单信息
  order:{
    // 返利订单
    fanliorder(params){
      return Ajax.post('/index/order/lists', params);
    },
    // 丢失订单
    lossorder(params){
      return Ajax.post('/index/order/loss', params);
    },
    // 添加银行卡
    addBank(params){
      return Ajax.post('/index/acount/add_bank', params);
    },
    // 获取提现方式
    getWithdrawalType(params){
      return Ajax.post('/index/user/currency', params);
    },
    // 添加支付宝
    addZFB(params){
      return Ajax.post('/index/acount/add_alipay', params);
    },
    // 删除关联卡号
    deleteAcount(params){
      return Ajax.post('/index/acount/del_account', params);
    },
    // 用户明细
    amountDetails(params){
      return Ajax.post('/index/user/remark', params);
    },
  },
  // 用户信息
  user:{
    // 获取用户金额
    getMoney(params){
      return Ajax.post('/index/user/money', params);
    },
    // 用户消息数量
    getUserMsgnum(params){
      return Ajax.post('/index/user/message', params);
    },
    // 用户消息列表
    UserMsgList(params){
      return Ajax.post('/index/user/meglists', params);
    },
    // 提现申请列表
    WithdrawalList(params){
      return Ajax.post('/index/user/txlists', params);
    },
    // 申请提现
    applyWithdrawal(params){
      return Ajax.post('/index/user/tixian', params);
    },
    // 用户消息通知
    getUserMsg(params){ 
      return Ajax.post('/index/user/meglists', params);
    },
    // 获取个人信息
    getUserInfo(params){
      return Ajax.post('/index/user/detail', params);
    },
    // 获取短信或者邮箱验证码
    getVerifiCode(params){
      return Ajax.post('/index/user/sendmsg', params);
    },
    // 验证用户是否存在
    verifyUserExists(params){
      return Ajax.post('/index/user/getusername', params); 
    },
    // 找回密码
    retrievePassword(params){
      return Ajax.post('/index/user/password', params); 
    },
    // 修改用户名
    editUsername(params){
      return Ajax.post('/index/user/edit/info', params); 
    },
    // 修改手机号
    editPhone(params){
      return Ajax.post('/index/user/edit/phone', params);
    },
    // 修改邮箱
    editEmail(params){
      return Ajax.post('/index/user/edit/email', params);
    },
    // 修改密码
    editPassword(params){
      return Ajax.post('/index/user/edit/password', params);
    },
    // 获取下级返利信息
    get_below_data(params){
      return Ajax.post('/index/user/getChild', params);
    },
    // 获取下级详情
    get_below_detail(params){
      return Ajax.post('/index/user/getChildDetail', params);
    }
  }

};
export default Request;
